<template>
  <v-card>
    <v-card-text class="px-0">
      <CrearSolicitudUsuario stepper-class="px-4 mt-4" />
    </v-card-text>
  </v-card>
</template>

<script>
import CrearSolicitudUsuario from './components/CrearSolicitudUsuario.vue';

export default {
  name: 'solicitudUsuarioView',
  components: { CrearSolicitudUsuario },
}
</script>